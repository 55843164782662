.orders-text-orderspage{
    text-align: center;
  margin-top: 20px;
}
.col-md-3 {
  flex: 0 0 auto;
 
}
#licence-bar{
  
  padding: 0;
  padding-left: 10px;
}
.order-wrapper{
  margin:2rem 8rem;
}
@media (max-width: 640px){
  .order-wrapper{
    margin:1rem 1.5rem;
  }
}
@media only screen and (max-width: 840px){
  .order-wrapper{
    margin:1rem 1.5rem;
  }
  .nav {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    row-gap: 10px;
  }
}

/* @media only screen and (max-width: 840px){
.form-aling {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    
}
} */

#search-btn1 {
 display: flex;
 
 
   margin-top: 10px;
  
  height: 60px;
}

.search-aling{
  margin-right: 0px;
  width: 100%;
  margin-top: 10px;
}

@media (min-width: 768px){
.col-md-6 {
    flex: 0 0 auto;
     width: 50%; 
}
}
/* .form-aling1{
padding-top: 10px;
} */
.form-floating {
  position: relative;
  padding-top: 10px;
}
@media (max-width: 640px){
#licence-bar {
   margin-left: 0rem; 
    /* width: 20rem; */
}
}

#licence-bar {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 640px){
#year-tab {
    flex: 0 0 auto;
     
    margin: 0;
}
}/* Wrapper for Horizontal Scrolling */
.order-wrapper div[style*="overflowX: auto"] {
  overflow-x: auto;
  width: 100%; /* Ensures it spans the container */
}

/* Fixed Width for the Table */
.order-wrapper .table {
  min-width: 900px; /* Adjusted table width for all columns */
  table-layout: fixed; /* Ensures consistent column widths */
}

/* Fixed Width for Specific Columns */
.table-no-aling {
  width: 50px; /* Serial number column */
  text-align: center;
}

.order-no-column {
  width: 150px; /* Fixed width for "Order No." column */
  text-align: left;
  word-wrap: break-word;
}

/* Order Column: Wrapping Long Content */
.order-column {
  word-wrap: break-word;
  white-space: normal; /* Allows wrapping for long content */
}

/* Date and Download Column Widths */
.date-column {
  width: 120px; /* Fixed width for "Date" column */
}

.download-column {
  width: 100px; /* Fixed width for "Download" column */
  text-align: center;
}

/* Center Align Icons in Download Column */
.ordrs-icon-aling {
  display: flex;
  justify-content: center;
  align-items: center;
}
