.careers-bt {
    width: 123px;
    background-color: #576CBC;
    color: #fff;
    height: 35px;
}

/* .carrer-main {
    margin-top: -12rem;
} */

.gallery-box {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.marquee-container1 {
    margin-top: 0rem;
    overflow-x: auto; /* Use 'auto' instead of 'visible' to enable horizontal scrolling */
}

/* Ensure images inside marquee container don't exceed viewport width */
.marquee-container1 img {
    max-width: 100%; /* Ensure images don't exceed viewport width */
    height: auto; /* Maintain aspect ratio */
}

.windmill {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    max-height: 25rem; /* Limit height to prevent vertical overflow */
}

.text {
    position: absolute;
}

.swiper-wrap {
    padding-bottom: 2rem;
}

.career-img-2 {
    height: 179px;
}

.card-align,
.card-align2 {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin-top: 1rem;
}

.careers.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 82%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.other-link {
    max-width: calc(100% - 102px); /* Adjust maximum width based on margin */
    border: 1px solid #000;
}

.other-link-head {
    color: #000000;
    text-align: center;
}

/* Media query for smaller screens */
@media only screen and (max-width: 375px) {
    .card-align,
    .card-align2 {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    .card-career-box {
        height: 12rem;
    }
}

/* Additional styles to address link responsiveness */
.otherlinks {
    margin-top: 1rem;
    height: auto; /* Adjust height for better responsiveness */
    width: 100%;
    background-color: white;
    border: 1px solid #000;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.otherlink-icon {
    height: 56px;
    background-color: #f3f5ff;
    color: black;
    width: 6rem;
}

.links {
    font-size: 15px;
    margin-top: -40px;
    margin-left: 0; /* Reset margin to prevent horizontal overflow */
    color: black;
    white-space: nowrap; /* Prevent link text from wrapping */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.links ul {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    list-style: none;
}
