.gallery-wrapper{
	margin:5rem 10rem;
}
.gallery-container{
	margin-bottom: 5rem;
}
.gallery-column{
	display:flex;
	flex-wrap:wrap;
}
.main-gallery{
	display:flex;
	flex-wrap:wrap;
	gap:2rem;
}
.gallery-thumbnail{
	height: 18rem;
	width:100%;
	object-fit:cover;
}
@media only screen and (max-width:1600px){
    .gallery-wrapper {
        margin: 5rem 3rem;
    }
}
@media (max-width: 640px){
	.gallery-wrapper {
		margin: 2rem 0rem;
	  }
}


