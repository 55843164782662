.marquee1{
    display: flex;
    column-gap: 30px;
    height: 7rem;
   
    overflow:hidden ;
}
.otherlinks-logo{
    width: 60px;
    height:60px ;
    object-fit: contain;
}
.link-text {
    padding-top: 5px;
    text-align: center;
    text-decoration: none;
    padding-left: 15px;
    color: black;
}
.box{
   display:flex;
	flex-wrap:wrap;
	gap:2rem;
}
.box a{
    text-decoration: none;
}

.link-boxes:hover{
    transform: scale(1.1);
    transition: all ease 0.5s;
}

.link-boxes{
    background-color: white;
    padding: 10px;
    height: 75px;
    box-shadow: 1px 1px 15px gray;
    transition: all ease 0.5s;
}