.faq {
  height: 80px;
  width: 50px; /* Slightly increased width */
  background-color: #00048b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.faq-align {
  position: fixed;
  right: 0;
  z-index: 1030;
  top: 42%; /* Default position */
}

.faq-align a {
  text-decoration: none;
}

.faq-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 20px;
  color: #fff;
  margin: 0;
  white-space: nowrap; /* Prevent text from breaking */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .faq-align {
    top: 40%; /* Adjust for medium screens */
  }
}

@media screen and (max-width: 480px) {
  .faq-align {
    top: 40%; /* Adjust for small screens */
  }
}
