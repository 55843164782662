#petition-btn {
    /* width: 14rem; */
    /* margin-left: 1.7rem; */
}
.login-box{
    background-color: #19376d;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    text-align: center;
}
.login-icon{
    margin-top: 40px;
}
#e-filling-btn{
    height: 4rem;
    width: 12rem;
    font-size: 24px;
    margin-top: -1rem;
}
.login-box-2{
    background-color:#19376d;
    width: 8rem;
    height: 3rem;
       text-align: center;
       padding-top: 10px;
    font-size: 36px;
}
.login-box-2 h6{
    margin-top: 7px;
}
.login-box-2 a{
    text-decoration: none;
    color: white;
}
.login-reg-tabs{
    margin-top: 2rem;
    /* margin-left: 20rem; */
    display: flex;
    column-gap: 5rem;
    justify-content: center;
}
.login-reg-boxes{
    /* margin-left: 20rem; */
    margin-bottom: 2rem;
    display: flex;
    column-gap: 5rem;
    justify-content: center;
}
.reg-tabs{
    /* margin-left: 30rem; */
}
.title{
    margin-top: 20px;
    display: flex;
    column-gap: 3.5rem;
    justify-content: center;
    text-align: center;
    /* margin-left: 22rem; */
}
.title #reg{
    margin-left: 2rem;
}
#new-user{
    padding-top: 5px;
    padding-left: 30px;
    font-size: 18px;
}


 /* #petition{
    margin-top:2rem;
    
} */
 /* #petition-btn{
    width:14rem;
    margin-left: 34rem;
}  */

/* #petition-search-area {
    margin-top: -2rem;
} */
.pulse2 {
    font-size: 18px;
    font-weight: 600;
    color: red;
  }
  .blink-hard2 {
    animation: blinker .5s step-end infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
   /* @media (max-width: 1600px){
    #petition {
       
        margin-top: 2rem;
      }
}  */

@media (max-width: 640px){
     /* #petition {
        margin-left: 5rem;
      }  */
      #e-filling-btn {
        height: 3rem;
        width: 11rem;
        font-size: 17px;
      }
      /* #petition-search-area {
        margin-top: 1rem;
      } */
      /* #petition-btn{
        width:14rem;
        margin-left: 3rem;
    } */
}

@media only screen and (max-width: 768px) {
    .archive-scroll-container {
      overflow-x: auto;
      overflow-y: auto; /* Enable vertical scrolling */
  max-height: 400px;
    }
    
    .archive-table {
      white-space: nowrap;
      min-width: 100%;
    }
  }
  .archive-scroll-container {
    
    overflow-y: auto; /* Enable vertical scrolling */
    height: 550px; /* Set a fixed height */
  }
  
  /* .archive-table {
    white-space: nowrap;
    min-width: 100%;
  } */
/* @media (max-width: 640px){
 #licence-bar {
     margin-left: 0; 
    /* width: 20rem; */
    /* margin-top: 10px;
} */
/* } */
/* @media (max-width: 640px){
#year-tab{
    /* flex: 0 0 auto; */
     /* width: 100%; 
    margin: 0;
    margin-bottom: 10px;
   
} */
/* }  */
.Search-aling{
    width: 100%;
    
}

#search-btn {
    margin-left: 1.2rem;
    margin-top: 10px;
    width: 200px;
    height: 60px;
}



@media only screen and (max-width: 840px){
.form-aling {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
}

.petition{
    display: flex;
    justify-content: center;
}
#e-filling-btn{
    width: 100%;
}
@media (max-width: 640px){
#year-tab {
   margin-left:0; 
   
}
}
