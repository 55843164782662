  /* @media (max-width: 640px){
    #chart-container {
        overflow-x: scroll;
      }
  } */

  @media (max-width: 640px){
    #chart-container {
        overflow-x: scroll;
        width: 100%;
      }
  }
   #chart-container img{
    justify-content: center;
   align-items: center;
   display: block;
   margin: auto;
   width: auto;
   height: auto;
   max-width: none;
  } 
  #chart-container{
    overflow-x: auto; /* Enable horizontal scrolling */
    text-align: center; /* Center the content horizontally */
  }