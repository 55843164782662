.gallery-img {
  /* height: 20rem; */
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.gallery-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#gallery-row {
  margin-bottom: 5rem;
}
/* .gallery-wrapper{
display:flex;
flex-direction:column;
} */
.gallery-wrap {
  margin: 2rem 8rem;
}

 #modal {
  z-index: 1000;
} 
.gallery-column p{
/* width:30rem; */

} 
.hover02-column p {
  text-align: center;
  font-size: 20px;

}

@media only screen and (max-width:1600px) {
  /* .gallery-wrap {
    margin: 2rem 2rem;
  } */
}

@media (max-width: 640px) {
  figure {
    width: 85rem;
    height: 300px;
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
}