/* .news-container{
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.news-image{
   margin-left: 5rem;
    width:60rem;
}
.news-page-content{
    padding-top: 5px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
}
#news-heading{
    text-align:center;
}
#news-heading h2{
    font-size:36px;
}
.news-desc{
    width: 60rem;
    text-align: center;
} */

.news-page-wrapper{
    justify-content: center;
    text-align: center;
}
.news-image{
     width:60rem;
     object-fit: cover;
 }
 .news-desc p{
    padding: 0 10rem;
    text-align: justify;
}
@media (max-width: 640px){
    .news-image{
        width:25rem;
        object-fit: cover;
    }
    .news-desc p{
       padding: 0 10px;
       text-align: justify;
   }
}