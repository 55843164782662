.startup-table{
    margin:2rem 8rem;
}
.startup-heading{
    text-align: center;
}

.startup-text{
    text-align: justify;
}
.startup-desc-text{
  font-weight: bold;
}
.wrapper{
    margin-top:2.5rem;
}
.startup-caption{
    width:30rem;
}