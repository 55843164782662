/* .about-body{
  margin-top:2rem;
    background:rgba(224, 244, 255, 0.793);
  } */
  .content{
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .content h3{
     color: #00048b;
     font-weight: 700;
     font-size: 32px;
  }
/* .about-main-container{
  margin: 3rem 8rem;
} */
#image {
  object-fit: cover;
  width: 100%;
  height: 20rem;
}
  .ab-text{
      font-size: 20px;
      font-family: math;
  }
  .profile-bt{
      width: 123px;
      background-color: #576CBC;
      color: #fff;
      height: 35px;
      margin-top: -17px;
  }
  .about-bt{
      width: 123px;
      background-color: #0b2447;
      color: #fff;
      height: 35px;
  }
  .row{
    margin-top: 4rem;
    display:flex;
    flex-direction:row;
  }
  .card{
    width: 15rem;
    height: 24rem;
    margin-bottom: 2rem; /* Add some margin between cards */
  margin-left: auto; /* Horizontally center the card within the column */
  margin-right: auto
  }
  .row-card-body{
    display: flex;
    flex-direction: column;
  }
  .card-img{
    width: 15rem;
    height: 15rem;
  }
  .card-text{
    text-align:center;
    font-size: 14px;
  }
  .card-title{
    padding-top: 10px;
  }
.chairman{
  font-size: 20px;
  font-weight: 600;
}
/* #card-aling-members{
  width: 18rem;
  height: auto;
 display: flex;
 flex-wrap: wrap;
 flex-direction: column;
} */
/* .card-img-members {
  width: 100%;
  object-fit: cover; 
  height: 100%; 
} */

.member-names{
  font-size:16px;
}
.card-descignation{
  font-weight:600;
  font-size:18px;
}
#card-aling-members-tech{
  margin-left:-2rem;
  width: 12rem;
  height: 21rem;
}
#member-names{
  font-size: 18px;
}
#staff-profiles{
 display: flex;
 justify-content: center;
} 
#chairman{
  margin-top:4rem;
}
@media (min-width: 1920px){
  .about-body{
    margin-top:-2rem;
      background:rgba(224, 244, 255, 0.793);
    }
}
@media (max-width: 1600px){
  .about-main-container{
    margin: 3rem 3rem;
  }
  #members{
    margin-left: 3rem;
  }
}
@media only screen and (max-width: 375px) {
  .ab-text{
    width: 90%;
  }
  #image{
    object-fit: cover;
    width: 90%;
    height: 20rem;
  }
  .news{
    margin-top: 2rem;
  }
  .whats-new-text-1{
    width: 90%;
  }
}
@media (max-width: 640px){
  .about-body {
    margin-top: 2rem;
    background: rgba(224, 244, 255, 0.793);
  }
  .about-main-container {
    margin: 2rem 2rem;
  }
  #about-container {
    width: 100%;
  }
 #image {
    object-fit: cover;
    width: 100%;
    height: 20rem;
  }
  /* .card {
    width: 15rem;
    height: 23rem;
  } */
  .ab-text {
    font-size: 16px;
  }
  #chairman {
    margin-top: 4rem;
    margin-left: 2rem;
  }
  /* #staff-profiles {
    margin-left: -2rem;
  } */
  .about-body{
  margin-top:-2rem;
    background:rgba(224, 244, 255, 0.793);
  }
}
/* @media only screen and (max-width:1600px){
#staff-profiles {
  margin-top: 5.5rem;
  margin-left: 1rem;
}
} */
/* @media only screen and (max-width: 1024px){
  #about-container{
    width: 100%;
  }
  #image{
    margin-left:5rem;
  }
  #staff-profiles {
    margin-top: 5.5rem;
    margin-left: 8rem;
  }
  #card-aling-members-tech {
    margin-left: -6rem;
    width: 12rem;
    height: 21rem;
  }
} */
@media only screen and (max-width: 1440px) {
  .about-main-container {
    margin: 3rem 2rem;
  }
  /* #image {
    object-fit: cover;
    width: 23rem;
    height: 20rem;
  } */
  /* #member-container{
    margin-left: 2rem;
  } */
  /* .card {
    width: 13rem;
    height: 22rem;
  } */
  .card-img {
    width: 13rem;
    height: 14rem;
  }
}
@media only screen and (max-width: 840px){
  #content {
    display: flex;
    flex-direction: row;
  }
  #about-container{
    width: 90%;
  }
  /* #staff-profiles {
    margin-left: 5rem;
  } */
  #card-aling-members-tech {
    margin-left: -6rem;
    width: 12rem;
    height: 21rem;
  }
  .about-body{
    margin-top:0rem;
      background:rgba(224, 244, 255, 0.793);
    }
    .solar-panel {
      width: 100%;
      height: 123vh;
      object-fit: cover;
    }
}
@media only screen and (max-width: 840px){
  #card-aling-members-tech {
    margin-left: 0rem;
  }
}
.card {
  width: 15rem;
  height: 24rem;
  margin-bottom: 2rem;
 margin-left:0; 
   margin-right: 0; 
}