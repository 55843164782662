.other-docs-table{
    margin:2rem 8rem;
}

@media (max-width: 640px){
    .other-docs-table{
        margin:1rem 1.5rem;
        font-size:13px;
    }
    #other-docs-search-bar{
        margin-left: 0rem;
    }
}
@media only screen and (max-width: 840px){
    .other-docs-table{
        margin:2rem 2rem;
    }
}

.row {
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}