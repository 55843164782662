
.StateAdvisory {
  margin: 0;
  padding: 0;
}
.StateAdvisory-img {
  width: 100%;
  height: 497px;
}
#download-btn {
  cursor: pointer;
}
.minutes-tab {
  margin-top: 2rem;
}
#sac-wrapper {
  margin-top: -3rem;
}
.reg-row-sac{
  justify-content: center;
}

@media (max-width: 480px) {
  #year-tab-sac {
    flex: 0 0 auto;
    
  }
  #sac-wrapper {
    margin-top: -3rem;
  }
}
@media only screen and (max-width: 840px) {
  .sac-search-area {
    margin-left: 0rem;
  }
}
