.press-table{
    margin:0 8rem;
}
.press-release-search-area{
    margin-top:2rem;
}
/* .reg-row{
    margin-left:35rem;
} */
@media (max-width: 640px){
    /* #press-release-search-area{
            margin-left: 4rem;
    } */
    .press-table{
        margin:0 1.5rem;
        font-size:13px;
    }
    /* #press-form-align {
        display: flex;
        justify-content: left; */
        /* margin-left: -2rem; */
      /* } */
}
@media only screen and (max-width: 840px){
    .press-table{
        margin:0 2rem;
    }
    .reg-row {
        width: 100%;
        /* margin-left: 9rem; */
      }
}

#search-btn-reg {
    /* margin-left: 1.2rem; */
    height: 42px;
    /* margin-top: 13px; */
    margin-bottom: 10px;
  width: 100%;
}

.reg-row1{
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

/* @media only screen and (max-width: 840px){
.form-aling {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 2rem;
}
}
@media only screen and (max-width: 840px){
#search-btn-reg {
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 10px;
}
}
@media (max-width: 640px){
#year-tab {
    margin-left: 0;
    width: 21.5rem;
}
} */

/* .form-aling {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 0;
    margin: 0;
    width: 344px;
} */
@media only screen and (max-width: 840px){
.form-aling {
    display: flex;
    justify-content: center;
   margin-top: 0rem;
}
}