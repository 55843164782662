.flip-card {
  background-color: transparent;
     width: 276px;
    height: 351px;
  perspective: 1000px;
  cursor: pointer;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-front {
  background-color: #fff;
  color: black;
}
.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}
.flip-card-back p{
  font-size: 14px;
}
.flip-card-back h3{
  padding-top: 10px;
  font-size: 20px;
}
.card-img-commission{
       height: 298px;
    width: 100%;
}
.card-commission-head{
    padding-top: 10px;
    font-size: 18px;
}
.commission-function-text{
    overflow: hidden;
}
.member-2{
  margin-top: 1rem;
  margin-left:11rem;
}
.members-profile{
  margin-top: 1rem;
  margin-left: 13rem;
}
.commettie{
  margin-top: 1rem;
 
}
#commettie{
  width: auto;
}
/* @media (max-width: 640px){
    #commission-card-container{
      margin-left: -4rem;
    }
} */