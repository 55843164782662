a{
    cursor:pointer;
}
.events{
    margin-bottom:5rem;
}
.event-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.gallery-wrapper{
	display:flex;
	flex-wrap:wrap;
}
.event-wrapper{
	display:flex;
	flex-wrap:wrap;
	gap:2rem;
}
.event-img{
	height: 20rem;
	width:100%;
	object-fit:cover;
}
.head{
	font-size: 20px;
	font-weight: 600;
}
@media(max-width:1400px){
	.head{
		font-size:18px;
		font-weight: 600;
	}	
}
@media(max-width:1200px){
	.head{
		font-size:16px;
		font-weight: 600;
	}	
}
@media(max-width:991px){
	.head{
		font-size:14px;
		font-weight: 600;
	}	
}
@media(max-width:768px){
	.head{
		font-size:18px;
		font-weight: 600;
	}	
}