#draft-regulation-search-area{
    margin-top: 0rem;
    margin-bottom: 2rem;
}
#draft-regulation-table{
    margin-top: 2rem;
}
#reg-form-align{
    display: flex;
    justify-content: left;
}
.Regulations{
    margin: 2rem 5rem;
}
#search-btn-reg{
   
    
    height: 3.5rem;
}

.Search-aling1 {
    width: 100%;
    margin-top: 0; 
}
/* .reg-row{
    margin-top: 10px;
    margin-left: 30rem;
} */
#search-btn1 {
    display: flex;
    margin-top: 0px;
   
    height: 60px;
}
#dropdown-area{
    width: 35rem;
}
/* .dropdown-wrapper{
    margin-left: 0;
} */

#dropdown-year{
    width: 15rem;
}
/* #regulation-year{
    margin-top: 1rem;
} */
#search-btn-finalreg {
    margin-top:19.19px;
    margin-left: 0;
    height:42px;
  
}
.final-reg-table{
    margin-top: 3rem;
    overflow-x: scroll;
}
#download-icon{
    color: black;
}
#table-heading-final-regulations{
    background-color: #A5D7E8;
    color: black;
    width:100%;
    height:4rem;
    text-align: center;
    padding: 15px 0;
}
@media (max-width: 640px){
    .Regulations{
        margin: 1rem 1.5rem;
    }
    #regulation-row{
        margin-left:0rem;
    }
    #year-tab {
        margin-left: 4rem;
       
      }
      .dropdown-wrapper {
        margin-left: 0rem;
      }
      #dropdown-area {
        width: 21rem;
      }
      #search-btn-finalreg {
        /* margin-top: -4rem; */
        margin-left: 0;
        height: 3rem;
      }
      #table-heading-final-regulations{
        font-size:18px;
      }
     #draft-regulation-table{
        overflow-x: scroll;
      } 
}
@media only screen and (max-width: 840px){
    #regulation-row{
        margin-top: 10px;
        margin-left: 0rem;
    }
    #search-btn-reg{
        margin-top: 5px;
    }
    /* .Search-aling {
        width: 460px;
        
      } */
      /* .dropdown-wrapper {
        margin-left: 0rem;
      } */
      #search-btn-finalreg {
        /* margin-top: rem; */
        /* margin-left: 16.4rem; */
        height: 3rem;
      }
      #draft-regulation-table{
        overflow-x: scroll;
      }
}


@media (max-width: 640px){
#year-tab1 {
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
}
}

@media (max-width: 768px) {
    .table-responsive {
      overflow-x: auto;
    }
    .overflow-table{
        overflow-x: auto;
    }
  }
  .table-responsive {
    overflow-x: auto;
  }
  .overflow-table{
    overflow-x: auto;
}
#search-btn-reg1 {
    margin-left: 1.2rem;
    height: 3.5rem;
    /* margin-top: 8px; */
}
.dropdown-wrapper {
    /* display: flex; */
    flex-direction:row;
    align-items: center;
    width: 100%;
  }
  
  .dropdown-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-bottom: 10px;
  }
  
  .regulation-label {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  
  
  .center-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .dropdown-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .regulation-label {
      width: 100%;
      margin-bottom: 5px;
    }
  
    .form-select {
      width: 100%;
    }
  
    .center-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
  
  .form-floating{
    padding-top: 0;
    width: 100%;
  }
  #search-btn1 {
    display: flex;
    margin-top: 0px;
   
    height: 60px;
    padding-top: 0px;
}

