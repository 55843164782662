#qutation-search{
    margin-top:-2.5rem;
    margin-left:5rem;
}
.qutation-table{
    margin-top:2rem;
}
@media (max-width: 640px){
    #qutation-search{
        margin-left:0rem;
    }
    .qutation-table{
       font-size:13px
    }
}

#search-btn{
    margin-left:0px;
    margin-top: 0px;
    width:100%;
    height: 42px;
    padding-left: 10px;
}
@media (max-width: 640px){
.col-md-3 {
    flex: 0 0 auto;
    width: 20rem;
 margin-left: 0rem;
}
}

@media (max-width: 640px){
#archives-search-area {
    flex: 0 0 auto;
  
    margin-left: 0;
    margin-top: 1rem;
}}
.col-md-3 {
    flex: 0 0 auto;
   
 margin-left: 0rem;
 
}

@media only screen and (max-width: 840px){
#archives-form {
   margin-top: 0rem; 
}
}
#search-btn1 {
    display: flex;
 
    height: 42px;
    padding-top: 0px;
}
.archives-search-area{
    width: 392px;
}
.wrapper{
    margin-top: 0;
}