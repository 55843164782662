@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Malayalam:wght@300;400;500;600;700&display=swap');
.inner-navbar-top{
  width: 100%;
  height: auto;
  background-color:  rgb(0 4 139);
  display: flex;
  column-gap: 2rem;
  justify-content: space-between;
  color: white;
  font-size: 16px;
}
.topnav{
  display: flex;
  column-gap: 10px;
  /* margin: 0 12rem; */
}
.topnav p{
 padding-top: 9px;
}
.line{
  margin-top: 8px;
 height: 27px;
 width: 2px;
 background-color: #ffffffa8;
 margin-left: 5px;
}
.screen_sizer{
  margin-top: 9px;
}
.screen_sizer ul {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-left: 0px;
}
.screen_sizer ul li{
  list-style: none;
}
.screen_sizer ul li a{
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
.icon{
  margin-top: 9px;
}

@media (max-width: 640px){
  .inner-navbar-top{
    width: 100%;
    height:max-content;
    background-color: rgb(0 4 139);
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 13px;
  }
  .topnav{
    display: flex;
    column-gap: 10px;
    margin: 0 0rem;
  }
}
  @media only screen and (max-width: 840px) {
    .inner-navbar-top {
      font-size: 12px;
      font-weight:600;
    }
    .topnav {
      display: flex;
      column-gap: 5px;
      margin: 0 2rem;
    }
  }
 
  .screen_sizer button{
    background-color: rgb(0 4 139) ;
    color: #fff;
    border: 0px;
  }

  .goog-te-banner-frame.skiptranslate, .goog-te-gadget-simple img {

    display: none !important;
  }
   .goog-tooltip{
    
    display: none limportant;
   }
    
    .goog-tooltip:hover{ display: none !important;}
    
    .goog-text-highlight { background-color: transparent important;
    
    border: none limportant;
    
    box-shadow: none !important;}
    
    .body{
    
    top: 0px !important;}

    .search-container {
      display: flex;
      align-items: center;
      margin-left: 0px;
    }
    
    .search-container input {
      padding: 5px;
      border: none;
      border-radius: 5px;
    }
    
    .search-container button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      
    }
    
    .search-results {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 5px;
      padding: 10px;
      position: absolute;
      top: 60px; /* Adjust according to your navbar height */
      width: 200px;
    }
    
    .search-result-item {
      padding: 5px 0;
      border-bottom: 1px solid #ddd;
    }
    @media only screen and (min-width: 1440px){
      .topnav {
        display: flex;
        column-gap: 5px;
        /* margin: 0 5rem; */
      }
    }
    @media (max-width: 991px) {
      .inner-navbar-top {
        flex-direction: column;
        align-items: center; /* Align content to the left */
        row-gap: 10px; /* Add space between rows */
        font-size: 12px;
      }
    
      .topnav {
        flex-wrap: nowrap; /* Allow the content to wrap onto the next line */
        row-gap: 10px; /* Space between rows within a single topnav */
        column-gap: 5px;
      }
      .topnav1 {
        flex-wrap:nowrap; /* Allow the content to wrap onto the next line */
        row-gap: 10px; /* Space between rows within a single topnav */
        column-gap: 5px;
      }
    
      .search-container {
        width: 100%; /* Make it take full width */
        margin-top: 10px; /* Add spacing from the above row */
        display: flex;
        justify-content: center; /* Center the search bar */
      }
    }
    @media (max-width: 546px) {
      .inner-navbar-top {
        flex-direction: column;
        align-items: flex-start; /* Align content to the left */
        row-gap: 10px; /* Add space between rows */
        font-size: 12px;
      }
    
      .topnav {
        flex-wrap: wrap; /* Allow the content to wrap onto the next line */
        row-gap: 10px; /* Space between rows within a single topnav */
        column-gap: 5px;
      }
      .topnav1 {
        flex-wrap:nowrap; /* Allow the content to wrap onto the next line */
        row-gap: 10px; /* Space between rows within a single topnav */
        column-gap: 5px;
      }
    
      .search-container {
        width: 100%; /* Make it take full width */
        margin-top: 10px; /* Add spacing from the above row */
        display: flex;
        justify-content: center; /* Center the search bar */
        margin-bottom: 10px;
      }
    }
 
  
   
    