.row-history{
    display:flex;
    gap:3rem;
    flex-wrap: nowrap;
}
.card-history{
    border:1px solid grey;
    height:20rem;
}
#history{
    margin-top:15px;
    margin-bottom:5rem;
}
.tenure{
    font-size:20px;
    font-weight:600;
}
@media (max-width: 640px){
    .row-history {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        

      }
      .card-history{
        border:1px solid grey;
        height:20rem;
        width:100%;
    }
    .card-img {
        width: 100%;
        height: 15rem;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
}

@media only screen and (max-width: 1440px){
.card-img {
    width: 100%;
    height: 14rem;
}
}

.card-img {
    width: 100%;
    height: 70%;
    object-fit: cover;
}