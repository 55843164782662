* {
    box-sizing: border-box;
  }
  h1,h2,h3,h4,h5,h6,label,span {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
  }
  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }
  .navbar-light {
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  .custom-control-label {
    font-weight: 400;
  }
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  .forgot-password a {
    color: #167bff;
  }
  .login-form{
    padding: 30px;
    margin-left: 45rem;
    width: 20rem;
  }
  @media (max-width: 640px){
    .login-form {
      margin-left: 5rem;
    }
  }