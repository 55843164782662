.Announcements{
    height: 58px;
   
    border: 1px solid #000;
   border-radius: 8px;
   margin-top: 0px;
   width: 100%;
}
 /* #home-search-bar {
  width: 100%;
} */


 /* #search{ 
  margin-top: -1rem;
} */
.Announcements-icon-img{
     height: 39px;
     margin-top: 6px;
     margin-left: 20px;
}
.Announcements-icon{
height: 58px;
background-color: #00048b;
border-radius: 8px;
width: 86px;
}
.Announcements-text{
 font-size: 15px;
    margin-top: -40px;
    margin-left: 103px;
    color: red;
    /* overflow: hidden; */
}
 /* .Marqueean-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative; */
    /* width: var(--width); */
    /* transform: var(--transform);
    width: 80%;
}
    */
    .form-aling2{
        display: flex;
        justify-content: center;
        align-items: center;
       width: 500px;
        text-align: center;
        margin-top: -5px;
    }
    @media only screen and (max-width: 375px) {
        .form-aling{
            display: flex;
            justify-content: center;
            flex-direction: column;
         }
       }
  /* .Search-aling{
    width: 460px;
  } */
  .Search-aling2 {
    width: 100%;
    margin-top: 0px;
   /* display: flex;
   justify-content: center;
   align-items: center;
   text-align: center; */
}
  @media only screen and (max-width: 375px) {
    .Search-aling2{
        width: 100%;
        margin-top: 10px;
       
     }
   }
  .year-aling{
    width: 134px;
    padding-left: 6px;
  }
  @media only screen and (max-width: 375px) {
    .year-aling{
        width: 354px;
        margin-top: 10px;
        padding-left: 0px;
     }
   }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    border: 1px solid #000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-select {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    border: 1px solid #000;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
#home-search-btn{
    margin-left: 0px;
    width: 100px;
    height: 57px;
    border-color: #00048b;
    color: #00048b;
    margin-top: 10px;}
#home-search-btn:hover{
  color: white
}
@media only screen and (max-width: 375px) {
    .search-bt{
        margin-left: 0px;
        margin-top: 10px;
     }
    
   }
.btn:hover {
    /* color: var(--bs-btn-hover-color); */
    /* background-color: var(--bs-btn-hover-bg); */
    background-color: rgb(0 4 139);
    color: #fff;
    border-color: var(--bs-btn-hover-border-color);
}
@media (max-width: 840px){
  #home-search-bar {
    width: 30vw;
  }
  }
  @media (max-width: 480px){
    #home-search-bar {
      width: 70vw;
    }
  }
@media (max-width: 640px){
  #search-area {
    margin-top: 1rem;
}
#home-search-btn {
   margin-left: 0px; 
  width: 100px;
  height: 57px;
  border-color: #00048b;
  color: #00048b;
  margin-top: 10px;
}
#home-search-bar {
  width: 66vw;
  margin-top: 12px;
  margin-bottom: 10px;
}
}
 .form-floating {
   position: relative; 
padding-top: 0px; 
display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;

}  



