.career{
    margin: 0;
    padding: 0;
}
.career-img {
    width: 100%;
    height: 497px;
}
.career-icon-aling{
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}
.wrapper{
    justify-content: center;
}
#search-btn{
    margin-left: 1.2rem;
}
@media (max-width: 640px){
    .wrapper {
        font-size: 13px;
      }
}

@media (max-width: 640px){
#archives-search-area {
    flex: 0 0 auto;
    /* width: 18rem; */
    margin-left: 0;
    margin-top: 1rem;
    /* justify-content: center; */
    /* align-items: center; */
    align-content: center;
}
}

.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
    height: calc(2.5rem + calc(var(--bs-border-width)* 2));
    min-height: calc(1.5rem + calc(var(--bs-border-width)* 2));
    line-height: 1.25;
}
.form-floating>.form-select {
    padding-top: 0;
    padding-bottom: 0 ;
}
.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 0.5rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}
.form-select {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 100%;
    padding: 0.5rem 2.35rem 0.5rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    border: 1px solid #000;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
}