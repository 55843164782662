.smart-city-caption{
     text-align: center;
}
.licensee-img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}
.smart-city-text{
     text-align: justify;
}
.startup-heading{
     font-size:30px;
}
@media only screen and (max-width: 1400px){
   
     .licensee-img {
          width: 100%;
          height: 210px;
          object-fit: cover;
      }
}
@media only screen and (max-width: 1200px){
   
     .licensee-img {
          width: 100%;
          height: 170px;
          object-fit: cover;
      }
}
@media only screen and (max-width: 991px){
   
     .licensee-img {
          width: 100%;
          height: 130px;
          object-fit: cover;
      }
}
@media only screen and (max-width: 840px){
     .smart-city-text{
          font-size:15px;
     }
     .licensee-img {
          width: 100%;
          height: auto;
          object-fit: cover;
      }
}
@media only screen and (max-width: 768px){
  
     .licensee-img {
          width: 100%;
          height: auto;
          object-fit: cover;
      }
}