.right-to-information{
    margin: 0;
    padding: 0;
}
.right-to-information-img{
    width: 100%;
    height: 497px;
}
.spio-note-text{
    text-align: center;
    font-size: 20px;
}
.bank-info > p{
    font-size:16px;
    font-weight:700;
}
.bank-info{
    margin-top:-4rem;
}
#rti-wrapper{
    margin-top:-2rem;
}
@media (max-width: 640px){
    .spio-note-text{
        font-size:15px;
    }
    .tab-content{
        overflow-x: scroll;
    }
}
@media only screen and (max-width: 840px){
    .tab-content{
        overflow-x: scroll;
    }
}