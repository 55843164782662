.link-tabs{
    margin-bottom: 3rem;
    /* margin-top: -6rem; */
    /* margin-left:8rem; */
    display:flex;
    flex-direction:row;
    column-gap:2rem;
}
.link-tabs a{
    text-decoration:none;
    text-align:center;
}
.link-box{
    text-align:center;
    border:1px solid #00048b;
    padding:10px;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #00048b;
    text-decoration:none;
    font-weight:500;
    font-size: 20px;
}
.link-box:hover{
    transform: scale(1.1);
    transition: 0.5s;
    background-color: #00048b;
    color: white;
}
@media only screen and (max-width: 640px){
    .link-tabs{
        /* margin-left:2rem;
        margin-right:2rem; */
        column-gap:1rem;
        flex-wrap: wrap;
        row-gap: 1rem;
    }
    .link-box{
        text-align:center;
        border:1px solid #00048b;
        padding:8px;
        padding-left: 1rem;
        padding-right: 1rem;
        color: #00048b;
        text-decoration:none;
        font-weight:500;
        font-size: 18px;
    }
}
@media only screen and (max-width: 991px) {
    .link-tabs {
        /* margin-left: 2rem; */
      }
      .link-tabs{
        /* margin-left:2rem;
        margin-right:2rem; */
        column-gap:1rem;
        flex-wrap: wrap;
        row-gap: 1rem;
    }
}
