.form-aling{

height: 42px;
}
#search-btn1 {
    display: flex;
    margin-top: 0px;
   
    height: 42px;
    padding-top: 0px;
}
@media (max-width: 640px){
#archives-search-area {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
}
}