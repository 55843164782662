.form-feedback{
    /* margin-bottom: 5rem;
   align-items: center; */
   /* margin-left: 28rem; */
   max-width: 600px; /* Adjust width as needed */
   margin: 0 auto; /* Center horizontally */
   padding: 20px; /* Add some padding for better appearance */
   /* background-color: #f8f9fa;  */
   /* border: 1px solid #dee2e6;  */
   border-radius: 8px; /* Optional: Add rounded corners */
   /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  */
}
/* #feedback-input{
    width:25vw;
} */
.captcha{
    margin-top: 2rem;
}
/* @media (max-width: 480px){
    .form-feedback{
        margin-bottom: 5rem;
       margin-left: 2rem;
    }
    #feedback-input{
        width:80vw;
    }
} */